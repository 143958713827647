<template>
  <v-container>
    <div id="nav">
      <Nav/>
    </div>
    <v-row no-gutters>
      <v-col/>
      <v-col cols="10">
        <div v-html="code"></div>
      </v-col>
      <v-col/>
    </v-row>
  </v-container>
</template>

<script>
import marked from "marked"
import Nav from "@/components/Nav";
import router from "@/router";

export default {
  name: "Jiaochen",
  props: ['article'],
  components: {
    Nav
  },
  data: () => ({
    code: ""
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.axios
          .get('md/' + this.$route.params.article + '.md')
          .then(response => (this.code = marked(response.data)))
          .catch(error => {
            console.log(error)
            router.push('/')
          })
    }
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    '$route': 'fetchData'
  }
}
</script>

<style scoped>

</style>